<template>
  <!-- 修改密码 -->
  <div class="page-container">
    <div class="page-top-tool">
      修改密码
    </div>
    <div class="form-layout">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="当前账号">
          <el-input v-model="form.username"  disabled size="mini" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pwd">
          <el-input v-model="form.pwd" size="mini" show-password placeholder="请输入" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input v-model="form.confirmPwd" size="mini" show-password placeholder="请输入" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="submitForm('ruleForm')">提 交</el-button>
          <el-button size="mini" @click="resetForm('ruleForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdatePwdPage',
  data() {
    const validateNewPwd = (rule, value, callback) => {
      if (value.trim().includes(' ')) {
        return callback(new Error('密码中不能包含空格'))
      }
      const arry = value.trim().split('')
      const len = arry.length
      if (len < 6) {
        return callback(new Error('密码长度不能小于8位'))
      }
      if (len > 16) {
        return callback(new Error('密码长度不能大于16位'))
      }
      callback()
    }
    const validateConfirmPwd = (rule, value, callback) => {
      if (value !== this.form.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        id: '',
        username: '',
        pwd: '',
        confirmPwd: '',
      },
      rules: {
        pwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validateNewPwd, trigger: 'blur' }
        ],
        confirmPwd: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: validateConfirmPwd, trigger: 'blur' }
        ]
      }
    };
  },

  mounted() {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.form.id = userInfo.id
      this.form.username = userInfo.username
    } else {
      this.$http({
        method: 'post',
        url: 'user_exit'
      })
      this.$router.push({
        path: '/login'
      })
    }
  },

  methods: {
    goBack() {
      this.$router.back()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            method: 'post',
            url: 'sysuser_update',
            params: {
              id: this.form.id,
              pwd: this.form.pwd
            }
          })
          .then(res => {
            this.loading = false
            if (res.errno == 0) {
              this.$message.success('修改成功')
              this.form.pwd = ''
              this.form.confirmPwd = ''
            } else {
              this.$message.error('修改失败')
            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('服务端异常')
          })
        } else {
          return false;
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }

  .form-layout {
    margin: 30px 0px;
    padding: 10px 30px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
</style>